











import { Component, Vue } from 'vue-property-decorator'

import EventStudyRuleForm from '@/partials/forms/Events/StudyRulesForm.vue'

@Component({
  components: {
    EventStudyRuleForm
  }
})
export default class StudyRuleEdit extends Vue {

}
